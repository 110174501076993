<template>
  <router-link :to="path" class="nav-link" aria-current="page">
    <span>{{$t(label)}}</span>
  </router-link>
</template>

<script>
export default {
  name: 'NavButton',
  props: {
    path: String,
    label: String
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: whitesmoke;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>