<template>
  <div class="grid-control-row w-100">
    <div class="d-flex flex-row-reverse py-2 px-3 w-100">
      <slot name="actions"></slot>
    </div>
    <div class="w-100" v-if="$slots.filters">
      <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#filtersCollapse" aria-expanded="false" aria-controls="filtersCollapse">
        <img src="@/assets/filter.png" alt="filter" width="20" height="20">
      </button>
      <div class="collapse multi-collapse" id="filtersCollapse">
        <slot name="filters"></slot>
      </div>
    </div>
    <div class="px-2 pb-3 w-100">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridControlTable"
}
</script>

<style scoped>
  .grid-control-row{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 45px 0px rgba(0,0,0,0.2);
    border-radius: 1rem;
  }
</style>