export default class Filter {
    page = null;
    limit = null;
    sortBy = null;
    sortDirection = null;
    filterFields = null;

    constructor(
        page = null,
        limit = null,
        sortBy = null,
        sortDirection = null,
        filterFields = null
    ) {
        this.page = page;
        this.limit = limit;
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
        this.filterFields = filterFields;
    }

    buildFilterQuery() {
        if (this.page == null && this.limit == null) {
            return '';
        }

        let filterQuery = '?';

        let ampReq = false;
        if (this.page != null) {
            filterQuery += 'page=' + this.page;
            ampReq = true;
        }

        if (this.limit != null) {
            if (ampReq) {
                filterQuery += '&';
            }
            filterQuery += 'limit=' + this.limit;
            ampReq = true;
        }

        if (this.sortBy != null) {
            if (ampReq) {
                filterQuery += '&';
            }
            filterQuery += 'sort[field]=' + this.sortBy;
            ampReq = true;
        }

        if (this.sortDirection != null) {
            if (ampReq) {
                filterQuery += '&';
            }
            filterQuery += 'sort[direction]=' + this.sortDirection;
            ampReq = true;
        }

        for (const [key, value] of Object.entries(this.filterFields)) {
            if (value.value === '' || value.value === null || value.value === undefined) {
                continue;
            }
            if (ampReq) {
                filterQuery += '&';
            }
            filterQuery += `filter[${key}][v]=${value.value}&filter[${key}][o]=${value.operation}`;
        }

        return filterQuery;
    }
}