<template>
  <select class="form-select" :value="value" @change="$emit('update:value', $event.target.value)">
    <option selected value=""> </option>
    <option v-for="(item, index) in selectOptions" :key="index" :value="index">{{ item }}</option>
  </select>
</template>

<script>
export default {
  name: "RevisionStateSelector",
  props: ['value'],
  emits: ['update:value'],
  data() {
    return {
      selectOptions: {
        SATISFACTORY: this.$t('revision.state.SATISFACTORY'),
        UNSATISFACTORY: this.$t('revision.state.UNSATISFACTORY')
      }
    }
  }
}
</script>

<style scoped>

</style>