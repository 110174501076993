import {createRouter, createWebHistory} from 'vue-router'

import CustomersGrid from './components/admin/customers/CustomersGrid.vue'
import EmailRulesGrid from './components/admin/email-rules/EmailRulesGrid.vue'
import EmailRulesExecutedGrid from './components/admin/email-rules-executed/EmailRulesExecutedGrid.vue'
import RevisionsGrid from './components/admin/revisions/RevisionsGrid.vue'
import UsersGrid from "./components/admin/users/UsersGrid.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/customers',
            component: CustomersGrid
        },
        {
            path: '/email-rules',
            component: EmailRulesGrid,
        },
        {
            path: '/email-rules-executed',
            component: EmailRulesExecutedGrid
        },
        {
            path: '/revisions',
            component: RevisionsGrid
        },
        {
            path: '/users',
            component: UsersGrid
        },
    ]
});

export default router;