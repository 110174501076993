<template>
  <div>
    <div v-if="!hasValidToken" style="height: 100vh" class="d-grid">
      <LoginForm></LoginForm>
    </div>
    <div class="container-fluid" v-if="hasValidToken">
      <div class="row">
        <div class="col-2 position-fixed px-0" id="sticky-sidebar">
          <AdminNavigation></AdminNavigation>
        </div>
        <div class="col-10 offset-2 px-4" id="main" style="padding-right: 0 !important;">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AdminNavigation from "@/components/admin/common/admin-navigation/AdminNavigation.vue";
import M from 'materialize-css'
import LoginForm from "@/components/login/LoginForm.vue";

export default {
  name: 'App',
  components: {LoginForm, AdminNavigation},
  data() {
    return {
      tokenRefreshTimer: null
    }
  },
  mounted() {
    M.AutoInit();
    this.$store.dispatch('refreshToken');
    this.tokenRefreshTimer = setInterval(() => {
      this.$store.dispatch('refreshToken');
    }, 300000)
  },
  computed: {
    hasValidToken() {
      return this.$store.getters.hasValidToken;
    }
  },
  beforeUnmount() {
    clearInterval(this.tokenRefreshTimer)
  }
}
</script>

<style>

</style>
