<template>
  <div class="d-flex w-100 justify-content-between py-1 px-3 rounded-2" style="background-color: lightgray">
    <i :class="fileIcon"></i>
    <span @click="$emit('fileDownloadRequest', this)" class="pointer">{{uploadedFileName}}</span>
    <i class="bi bi-x-lg pointer" @click="$emit('fileDeleteRequest', this)"></i>
  </div>

</template>
<script>
export default {
  name: "UploadedFileListItem",
  props: {
    uploadedFileType: String,
    uploadedFileName: String,
    uploadedFileId: Number
  },
  computed: {
    fileIcon() {
      switch (this.uploadedFileType) {
        case '':
          return '';
        default:
          return 'bi bi-file-earmark-ruled-fill';
      }
    }
  },
  methods: {
    getFileId(){
      return this.uploadedFileId;
    },
    getFileType(){
      return this.uploadedFileType;
    },
    getFileName(){
      return this.uploadedFileName;
    }
  },
  expose: ['getFileId', 'getFileType', 'getFileName']
}
</script>

<style scoped>
@import "bootstrap-icons";
</style>