<template>
  <div class="d-flex justify-content-center my-auto">
    <div class="login-box px-3 py-5">
      <div class="d-flex justify-content-center">
        <img :src="require('@/assets/login-pic.webp')" alt="login" class="login-pic">
      </div>
      <div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">{{ $t('login.form.email') }}</label>
          <input type="email" class="form-control" required v-model.trim="v$.email.$model" v-on:keyup.enter="login">
          <div v-if="v$.email.$error" class="text-danger">Invalid email</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">{{ $t('login.form.password') }}</label>
          <input type="password" class="form-control" required v-model.trim="v$.password.$model" v-on:keyup.enter="login">
          <div v-if="v$.password.$error" class="text-danger">Required</div>
        </div>
        <button @click="login" class="btn btn-primary">{{ $t('login.form.submit') }}</button>
        <div v-if="loginError !== null">{{ loginError }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";

export default {
  name: "LoginForm",
  data() {
    return {
      email: '',
      password: '',
      loginError: ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      }
    }
  },
  methods: {
    async login(){
      let validationResult = await this.v$.$validate();
      if (!validationResult) {
        return;
      }

      let thisProxy = this;
      thisProxy.loginError = null;

      this.$store.dispatch('login', {
        username: this.email,
        password: this.password
      }).then(() => {
        thisProxy.v$.$reset();
      }).catch((error) => {
        thisProxy.v$.$reset();
        console.log(error);
        thisProxy.loginError = thisProxy.$t('login.form.invalidCredentials')
      });
    }
  }
}
</script>

<style scoped>
  .login-box{
    background-color: #FFFFFF;
    box-shadow: 0 0 45px 0 rgba(0,0,0,0.2);
    border-radius: 1rem;
  }
  .login-pic {
    border-radius: 2rem;
    width: 20%;
  }
</style>