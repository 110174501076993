<template>
  <div class="offcanvas offcanvas-end w-50" data-bs-scroll="false" data-bs-backdrop="true" :class="show ? 'show' : ''" tabindex="-1">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title">{{ title }}</h5>
      <button type="button" class="btn-close text-reset" @click="$emit('closeRequest')"></button>
    </div>
    <div class="offcanvas-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OffCanvas",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>