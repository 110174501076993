<template>
  <div class="badge bg-secondary pointer d-flex justify-content-between" @click="$emit('removeRequest', tagIndex)"><span>{{ tagValue }}</span><span>X</span></div>
</template>

<script>
export default {
  name: "MultiValueTag",
  props: {
    tagValue: String,
    tagIndex: Number
  }
}
</script>

<style scoped>

</style>