import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

// @ts-ignore
import TipTapMentionList from "./TipTapMentionList.vue"

export default {
    char: '/',
    items: ({ query }) => {
        return [
            'date_current',
            'time_current',
            'recipient_email',
            'customer_name',
            'customer_identification',
            'customer_address',
            'revision_validTill',
            'revision_address',
            'revision_price',
            'revision_type',
            'revision_state',
            'revision_description'
        ].filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(TipTapMentionList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}