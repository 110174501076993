<template>
  <div class="d-flex flex-column flex-shrink-0 text-white bg-dark vh-100 w-100 position-relative overflow-hidden">
    <div class="nav-logo py-3 px-4">
      <div href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none w-100"
           style="height: 4rem">
        <router-link to="/">
          <img src="@/assets/re-storage-logo-white.svg" alt="RE/STORAGE" class="w-100 h-100">
        </router-link>
      </div>
    </div>
    <ul class="nav flex-column mb-auto">
      <li class="nav-item" v-for="(item, index) in availableNavigationLinks" v-bind:key="index">
        <NavButton
            :path="item.path"
            :label="item.label"
        />
      </li>
    </ul>
    <div class="nav-bottom">
      <div class="px-3 py-2">
        <span @click="logout" class="pointer">{{ $t('navigation.label.logout') }}</span>
      </div>
    </div>
    <!--    <div class="dropdown">-->
    <!--      <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">-->
    <!--        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">-->
    <!--        <strong>mdo</strong>-->
    <!--      </a>-->
    <!--      <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">-->
    <!--        <li><a class="dropdown-item" href="#">New project...</a></li>-->
    <!--        <li><a class="dropdown-item" href="#">Settings</a></li>-->
    <!--        <li><a class="dropdown-item" href="#">Profile</a></li>-->
    <!--        <li><hr class="dropdown-divider"></li>-->
    <!--        <li><a class="dropdown-item" href="#">Sign out</a></li>-->
    <!--      </ul>-->
    <!--    </div>-->
  </div>
</template>

<script>
import NavButton from "@/components/admin/common/admin-navigation/components/NavButton.vue";

export default {
  name: 'AdminNavigation',
  components: {
    NavButton
  },
  computed: {
    availableNavigationLinks() {
      return this.$store.state.availableNavigationLinks
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('logout');
    }
  }
};
</script>

<style scoped>
ul.nav {
  z-index: 1;
}

ul.nav a:hover {
  color: whitesmoke !important;
  background-color: dimgray;
  border-left: 3px solid var(--primary);
}

.nav-logo {
  border-bottom: 1Q solid dimgray;
}

</style>