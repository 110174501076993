<template>
  <div class="position-relative">
    <AlertPopup ref="alert"></AlertPopup>
    <div class="container-fluid">
      <div class="row my-2">
        <div class="col-12">
          <h2>{{ $t('grid.customers.header') }}</h2>
        </div>
      </div>
      <GridControlTable>
        <template v-slot:actions>
          <div v-for="(item, key) in globalLinks" v-bind:key="key">
            <button type="button" class="btn btn-custom-primary" @click="handleOperation(item, key, null)">
              <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="30" class="pointer">
            </button>
          </div>
        </template>
        <template v-slot:filters>
          <FilterTab :filter-data="filterData" @update-filter="loadFormServer"></FilterTab>
        </template>
        <template v-slot:body>
          <EasyDataTable
              theme-color="var(--accent)"
              buttons-pagination
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="items"
          >
            <template #loading>
              <img src="@/assets/loading.gif" style="width: 80px; height: 80px;" alt="loading"/>
            </template>
            <template #item-_links="items">
              <div class="d-flex justify-content-evenly">
                <a v-for="(item, key) in items._links" v-bind:key="key" @click="handleOperation(item, key, items)">
                  <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="15" class="pointer">
                </a>
              </div>
            </template>
            <template #expand="item">
              <div style="padding: 15px">
                {{ item.description }}
              </div>
            </template>
          </EasyDataTable>
        </template>
      </GridControlTable>
    </div>
    <OffCanvas :show="showForm" @close-request="showForm = false">
      <UniversalForm :field-configuration="formFieldConfiguration" :ref="'form'"></UniversalForm>
    </OffCanvas>
  </div>
</template>

<script>
// TODO UPGRADES dropdown alebo buttony pre hromadne akcie
import UniversalForm from "@/components/admin/common/edit-form/UniversalForm.vue";
import FromFieldString from "@/components/admin/common/edit-form/fields/FormFieldString.vue";
import OffCanvas from "@/components/admin/common/grid/OffCanvas.vue";
import FormFieldCollection from "@/components/admin/common/edit-form/fields/FormFieldCollection.vue";
import FormFieldString from "@/components/admin/common/edit-form/fields/FormFieldString.vue";
import FormFieldText from "@/components/admin/common/edit-form/fields/FormFieldText.vue";

import { required, minLength, email } from '@vuelidate/validators'
import AlertPopup from "@/components/admin/common/grid/AlertPopup.vue";
import GridControlTable from "@/components/admin/common/grid/GridControlTable.vue";
import FilterTab from "@/components/admin/common/filtering/FilterTab.vue";

export default {
  name: 'CustomersGrid',
  components: {
    FilterTab,
    GridControlTable,
    AlertPopup,
    OffCanvas,
    EasyDataTable: window['EasyDataTable'],
    UniversalForm: UniversalForm
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: this.$t('grid.customers.grid.header.name'), value: "name", sortable: true},
        { text: this.$t('grid.customers.grid.header.address'), value: "address" },
        { text: this.$t('grid.customers.grid.header.identification'), value: "identification", sortable: true},
        { text: this.$t('grid.customers.grid.header.primary_contact_email'), value: "primaryContactEmail" },
        { text: this.$t('grid.customers.grid.header.emails_for_cc'), value: "emailsForCC" },
        { text: this.$t('grid.customers.grid.header.tags'), value: "tags" },
        { text: this.$t('grid.customers.grid.header.operations'), value: "_links" },
      ],
      filterData: {
        name: {
          title: this.$t('grid.customers.grid.header.name'),
          value: '',
          operation: 'swith',
          inputType: 'string'
        },
        primaryContactEmail: {
          title: this.$t('grid.customers.grid.header.primary_contact_email'),
          value: '',
          operation: 'eq',
          inputType: 'string'
        },
        tags: {
          title: this.$t('grid.customers.grid.header.tags'),
          value: '',
          operation: 'eq',
          inputType: 'string'
        },
      },
      formFieldConfiguration: [
        {
          name: {
            key: 'name',
            fieldOptions: {
              title: this.$t('grid.customers.form.name.title'),
              validations: {
                required,
                minLength: minLength(2)
              },
            },
            component: FromFieldString
          },
          identification: {
            key: 'identification',
            fieldOptions: {
              title: this.$t('grid.customers.form.identification.title'),
              validations: {
                required,
              },
            },
            component: FromFieldString
          }
        },
        {
          primaryContactEmail: {
            key: 'primaryContactEmail',
            fieldOptions: {
              title: this.$t('grid.customers.form.primary_contact_email.title'),
              validations: {
                email
              },
            },
            component: FormFieldString
          },
          emailsForCC: {
            key: 'emailsForCC',
            fieldOptions: {
              title: this.$t('grid.customers.form.emails_for_cc.title'),
              component: FormFieldString,
              validations: {
                required,
                email
              },
            },
            component: FormFieldCollection
          }
        },
        {
          address: {
            key: 'address',
            fieldOptions: {
              title: this.$t('grid.customers.form.address.title'),
              validations: {
                required,
              },
            },
            component: FromFieldString
          },
          tags: {
            key: 'tags',
            fieldOptions: {
              title: this.$t('grid.customers.form.tags.title'),
              component: FormFieldString,
              validations: {
                required,
                minLength: minLength(2)
              },
            },
            component: FormFieldCollection
          }
        },
        {
          description: {
            key: 'description',
            fieldOptions: {
              title: this.$t('grid.customers.form.description.title'),
              validations: {
                minLength: minLength(2)
              },
            },
            component: FormFieldText
          }
        },
      ],
      items: [],
      loading: false,
      serverItemsLength: 0,
      globalLinks: {},
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: 'id',
        sortType: 'asc',
      },
      showForm: false,
    }
  },
  methods: {
    async loadFormServer() {
      this.loading = true;

      this.$store.dispatch('getCustomers', {...this.serverOptions, filter: this.filterData})
          .then((result) => {
            this.items = result.data;
            this.serverItemsLength = result.total;
            this.globalLinks = result._links;
            this.loading = false;
          });
    },
    async submitCreateForm(values){
      let alert = this.$refs.alert;
      this.$store.dispatch('createCustomer', values)
          .then(() => {
            this.loadFormServer();
            this.showForm = false;
            alert.success('Successfully created!', 2000);
          })
          .catch(() => {
            alert.error('Create failed!', 2000);
          });
    },
    createSubmitEditFormCallback(editedEntityId){
      const loadFormServerProxy = this.loadFormServer;
      const showFormProxy = (value) => this.showForm = value;
      let alert = this.$refs.alert;
      return async function submitEditForm(values){
        this.$store.dispatch('updateCustomer', {data: values, id: editedEntityId})
            .then(() => {
              loadFormServerProxy();
              showFormProxy(false);
              alert.success('Successfully updated!', 2000);
            })
            .catch((reason) => {
              console.log(reason);
              alert.error('Update failed!', 2000);
            });
      }
    },
    handleOperation(url, operationCode, entry){
      let formComponent = this.$refs['form'];
      let alert = this.$refs.alert;
      switch (operationCode) {
        case 'create':
          formComponent.setSubmitCallback(this.submitCreateForm);
          formComponent.empty();
          this.showForm = true;
          break;
        case 'update':
          formComponent.setSubmitCallback(this.createSubmitEditFormCallback(entry.id));
          formComponent.prefill(entry);
          this.showForm = true;
          break;
        case 'remove':
          this.$store.dispatch('deleteCustomer', entry)
              .then(() => {
                this.loadFormServer();
                alert.success('Successfully deleted!', 2000);
              })
              .catch(() => {
                alert.error('Delete failed!', 2000);
              });
          break;
      }
    }
  },
  mounted() {
    this.loadFormServer()
  },
  watch: {
    serverOptions: {
      handler() {
        this.loadFormServer();
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>