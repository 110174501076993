import {mergeAttributes} from '@tiptap/core'
import { Mention } from "@tiptap/extension-mention";
import moment from "moment/moment";

export const TipTapExposedValueNode = Mention.extend({
    name: 'TipTapExposedValueNode',

    renderHTML({ node, HTMLAttributes }) {
        moment.locale('sk');

        let labelToRender = node.attrs.id;
        switch (true) {
            case /date_current/.test(node.attrs.id):
                labelToRender = moment().format('L')
                break;
            case /time_current/.test(node.attrs.id):
                labelToRender = moment().format('LT')
                break;
            case /recipient_email/.test(node.attrs.id):
                labelToRender = 'peter.horvath@example.com'
                break;
            case /customer_name/.test(node.attrs.id):
                labelToRender = 'Peter Horvath'
                break;
            case /customer_identification/.test(node.attrs.id):
                labelToRender = '123456789'
                break;
            case /customer_address/.test(node.attrs.id):
                labelToRender = 'Janka Krala 123/345, Zilina'
                break;
            case /revision_validTill/.test(node.attrs.id):
                labelToRender = '30.2.2030'
                break;
            case /revision_price/.test(node.attrs.id):
                labelToRender = '320.80€'
                break;
            case /revision_type/.test(node.attrs.id):
                labelToRender = 'SPECIAL'
                break;
            case /revision_state/.test(node.attrs.id):
                labelToRender = 'SUCCESS'
                break;
            case /revision_description/.test(node.attrs.id):
                labelToRender = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci sapien, accumsan non varius ut, interdum quis metus. Aliquam erat volutpat.'
                break;
        }
        return [
            'span',
            mergeAttributes({ 'data-type': this.name, 'data-placeholder-tag': '{{ ' + node.attrs.id + ' }}'}, this.options.HTMLAttributes, HTMLAttributes),
            labelToRender
        ]
    },
})