<template>
  <div class="position-relative">
    <AlertPopup ref="alert"></AlertPopup>
    <div class="container-fluid">
      <div class="row my-2">
        <div class="col-12">
          <h2>{{ $t('grid.users.header') }}</h2>
        </div>
      </div>
      <GridControlTable>
        <template v-slot:actions>
          <div v-for="(item, key) in globalLinks" v-bind:key="key">
            <button type="button" class="btn btn-custom-primary" @click="handleOperation(item, key, null)">
              <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="30" class="pointer">
            </button>
          </div>
        </template>
        <template v-slot:filters>
          <FilterTab :filter-data="filterData" @update-filter="loadFormServer"></FilterTab>
        </template>
        <template v-slot:body>
          <EasyDataTable
              theme-color="var(--accent)"
              buttons-pagination
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="items"
          >
            <template #loading>
              <img src="@/assets/loading.gif" style="width: 80px; height: 80px;" alt="loading"/>
            </template>
            <template #item-_links="items">
              <div class="d-flex justify-content-evenly">
                <a v-for="(item, key) in items._links" v-bind:key="key" @click="handleOperation(item, key, items)">
                  <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="15" class="pointer">
                </a>
              </div>
            </template>
          </EasyDataTable>
        </template>
      </GridControlTable>
    </div>
    <OffCanvas :show="showForm" @close-request="showForm = false">
      <UniversalForm :field-configuration="formFieldConfiguration" ref="form"></UniversalForm>
    </OffCanvas>
  </div>
</template>

<script>
import OffCanvas from "@/components/admin/common/grid/OffCanvas.vue";
import UniversalForm from "@/components/admin/common/edit-form/UniversalForm.vue";
import {email, required, minLength} from "@vuelidate/validators";
import FormFieldPassword from "@/components/admin/common/edit-form/fields/FormFieldPassword.vue";
import FormFieldCollection from "@/components/admin/common/edit-form/fields/FormFieldCollection.vue";
import AlertPopup from "@/components/admin/common/grid/AlertPopup.vue";
import FormFieldString from "@/components/admin/common/edit-form/fields/FormFieldString.vue";
import FormFieldSelect from "@/components/admin/common/edit-form/fields/FormFieldSelect.vue";
import GridControlTable from "@/components/admin/common/grid/GridControlTable.vue";
import FilterTab from "@/components/admin/common/filtering/FilterTab.vue";

export default {
  name: 'UsersGrid',
  components: {
    FilterTab,
    GridControlTable,
    AlertPopup,
    OffCanvas,
    EasyDataTable: window['EasyDataTable'],
    UniversalForm: UniversalForm
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: this.$t('grid.users.grid.header.email'), value: "email", sortable: true },
        { text: this.$t('grid.users.grid.header.roles'), value: "roles" },
        { text: this.$t('grid.users.grid.header.operations'), value: "_links" },
      ],
      filterData: {
        email: {
          title: this.$t('grid.users.grid.header.email'),
          value: '',
          operation: 'like',
          inputType: 'string'
        },
      },
      formFieldConfiguration: [
        {
          email: {
            key: 'email',
            fieldOptions: {
              title: this.$t('grid.users.form.email.title'),
              validations: {
                required,
                email
              },
            },
            component: FormFieldString
          },
          roles: {
            key: 'roles',
            fieldOptions: {
              title: this.$t('grid.users.form.roles.title'),
              component: FormFieldSelect,
              validations: {
                required,
              },
              selectOptions: {
                ROLE_ADMIN: 'Admin',
                ROLE_REVISION_MANAGER: 'Revision manager',
                ROLE_REVISION_EDITOR: 'Revision editor'
              },
              allowRepeat: false
            },
            component: FormFieldCollection
          }
        },
        {
          password: {
            key: 'password',
            fieldOptions: {
              title: this.$t('grid.users.form.password.title'),
              validations: {},
            },
            component: FormFieldPassword
          }
        }
      ],
      items: [],
      loading: false,
      serverItemsLength: 0,
      globalLinks: {},
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: 'id',
        sortType: 'asc',
      },
      showForm: false,
    }
  },
  methods: {
    async loadFormServer() {
      this.loading = true;

      this.$store.dispatch('getUsers', {...this.serverOptions, filter: this.filterData})
          .then((result) => {
            this.items = result.data;
            this.serverItemsLength = result.total;
            this.globalLinks = result._links;
            this.loading = false;
          });
    },
    async submitCreateForm(values){
      let alert = this.$refs.alert;
      this.$store.dispatch('createUser', values)
          .then(() => {
            this.loadFormServer();
            this.showForm = false;
            alert.success('Successfully created!', 2000);
          })
          .catch(() => {
            alert.error('Create failed!', 2000);
          });
    },
    createSubmitEditFormCallback(editedEntityId){
      const loadFormServerProxy = this.loadFormServer;
      const showFormProxy = (value) => this.showForm = value;
      let alert = this.$refs.alert;
      return async function submitEditForm(values){
        this.$store.dispatch('updateUser', {data: values, id: editedEntityId})
            .then(() => {
              loadFormServerProxy();
              showFormProxy(false);
              alert.success('Successfully updated!', 2000);
            })
            .catch((reason) => {
              console.log(reason);
              alert.error('Update failed!', 2000);
            });
      }
    },
    handleOperation(url, operationCode, entry){
      let formComponent = this.$refs['form'];
      let alert = this.$refs.alert;
      switch (operationCode) {
        case 'create':
          this.formFieldConfiguration[1].password.fieldOptions.validations = {
            required,
            minLength: minLength(8)
          };
          formComponent.setSubmitCallback(this.submitCreateForm);
          formComponent.empty();
          this.showForm = true;
          break;
        case 'update':
          this.formFieldConfiguration[1].password.fieldOptions.validations = {};
          formComponent.setSubmitCallback(this.createSubmitEditFormCallback(entry.id));
          formComponent.prefill(entry);
          this.showForm = true;
          break;
        case 'remove':
          this.$store.dispatch('deleteUser', entry)
              .then(() => {
                this.loadFormServer();
                alert.success('Successfully deleted!', 2000);
              })
              .catch(() => {
                alert.error('Delete failed!', 2000);
              });
          break;
      }
    }
  },
  mounted() {
    this.loadFormServer()
  },
  watch: {
    serverOptions: {
      handler() {
        this.loadFormServer();
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>