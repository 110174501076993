<template>
  <div>
    <div>{{ title }}</div>
    <TipTapEditor v-model="v$.value.$model" ref="editor"/>
    <div v-if="v$.value.$error" class="text-danger">Name field has an error.</div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import TipTapEditor from "@/components/admin/common/edit-form/fields/elements/TipTapEditor.vue";

export default {
  name: "FormFieldRichText",
  components: {
    TipTapEditor,
  },
  props: {
    fieldOptions: Object,
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      value: "",
    }
  },
  computed: {
    title() {
      if (this.fieldOptions.title !== null) {
        return this.fieldOptions.title;
      }
      return '---';
    }
  },
  validations() {
    if (typeof this.fieldOptions.validations !== "undefined") {
      return {value: this.fieldOptions.validations};
    }
    return {value: {}};
  },
  methods: {
    getValue() {
      return this.value;
    },
    setValue(value) {
      this.value = value;
      this.v$.$reset();
    },
    async validate() {
      let validationResult = await this.v$.$validate();
      if (validationResult) {
        this.v$.$reset();
      }

      return validationResult;
    }
  },
  expose: ['getValue', 'setValue', 'validate']
}
</script>

<style scoped>
</style>