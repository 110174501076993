<template>
  <div class="position-relative">
    <AlertPopup ref="alert"></AlertPopup>
    <div class="container-fluid">
      <div class="row my-2">
        <div class="col-12">
          <h2>{{ $t('grid.email_rules.header') }}</h2>
        </div>
      </div>
      <GridControlTable>
        <template v-slot:actions>
          <div v-for="(item, key) in globalLinks" v-bind:key="key">
            <button type="button" class="btn btn-custom-primary" @click="handleOperation(item, key, null)">
              <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="30" class="pointer">
            </button>
          </div>
        </template>
        <template v-slot:body>
          <EasyDataTable
              theme-color="var(--accent)"
              buttons-pagination
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="items"
          >
            <template #loading>
              <img src="@/assets/loading.gif" style="width: 80px; height: 80px;" alt="loading"/>
            </template>
            <template #item-_links="items">
              <div class="d-flex justify-content-evenly">
                <a v-for="(item, key) in items._links" v-bind:key="key" @click="handleOperation(item, key, items)">
                  <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="15" class="pointer">
                </a>
              </div>
            </template>
            <template #item-executionTime="items">
              {{ formatExecutionTime(items.executionTime) }}
            </template>
            <template #item-sendToCustomerPrimary="items">
              {{ formatBoolean(items.sendToCustomerPrimary) }}
            </template>
            <template #item-sendToCC="items">
              {{ formatBoolean(items.sendToCC) }}
            </template>
          </EasyDataTable>
        </template>
      </GridControlTable>
      <OffCanvas :show="showForm" @close-request="showForm = false">
        <UniversalForm :field-configuration="formFieldConfiguration" ref="form"></UniversalForm>
      </OffCanvas>
    </div>
  </div>
</template>

<script>
import AlertPopup from "@/components/admin/common/grid/AlertPopup.vue";
import OffCanvas from "@/components/admin/common/grid/OffCanvas.vue";
import UniversalForm from "@/components/admin/common/edit-form/UniversalForm.vue";
import {required} from "@vuelidate/validators";
import FormFieldString from "@/components/admin/common/edit-form/fields/FormFieldString.vue";
import FormFieldCollection from "@/components/admin/common/edit-form/fields/FormFieldCollection.vue";
import FormFieldCheckbox from "@/components/admin/common/edit-form/fields/FormFieldCheckbox.vue";
import FormFieldTimeInterval from "@/components/admin/common/edit-form/fields/FormFieldTimeInterval.vue";
import FormFieldRichText from "@/components/admin/common/edit-form/fields/FormFieldRichText.vue";
import GridControlTable from "@/components/admin/common/grid/GridControlTable.vue";

export default {
  name: 'EmailRulesGrid',
  components: {
    GridControlTable,
    AlertPopup,
    OffCanvas,
    EasyDataTable: window['EasyDataTable'],
    UniversalForm: UniversalForm
  },
  data() {
    return {
      headers: [
        {text: "ID", value: "id", sortable: true},
        {text: this.$t('grid.email_rules.grid.header.execution_time'), value: "executionTime", sortable: true},
        {text: this.$t('grid.email_rules.grid.header.subject'), value: "subject"},
        {text: this.$t('grid.email_rules.grid.header.recipients'), value: "recipients"},
        {text: this.$t('grid.email_rules.grid.header.send_to_customer_primary'), value: "sendToCustomerPrimary"},
        {text: this.$t('grid.email_rules.grid.header.send_to_cc'), value: "sendToCC"},
        {text: this.$t('grid.email_rules.grid.header.operations'), value: "_links"},
      ],
      formFieldConfiguration: [
        {
          subject: {
            key: 'subject',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.subject.title'),
              validations: {
                required,
              },
            },
            component: FormFieldString
          },
          recipients: {
            key: 'recipients',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.recipients.title'),
              component: FormFieldString,
              validations: {
                required,
                recipientsValidator(value) {
                  // eslint-disable-next-line
                  const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                  const allowedAliases = ['config.admin', 'revision.creator'];
                  return value.match(emailRegex) || allowedAliases.includes(value);
                }
              },
            },
            component: FormFieldCollection
          },
        },
        {
          executionTime: {
            key: 'executionTime',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.execution_time.title'),
              validations: {
                required,
              },
            },
            component: FormFieldTimeInterval
          },
        },
        {
          sendToCustomerPrimary: {
            key: 'sendToCustomerPrimary',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.send_to_customer_primary.title'),
            },
            component: FormFieldCheckbox
          },
          sendToCC: {
            key: 'sendToCC',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.send_to_cc.title'),
            },
            component: FormFieldCheckbox
          }
        },
        {
          emailTemplate: {
            key: 'emailTemplate',
            fieldOptions: {
              title: this.$t('grid.email_rules.form.email_template.title'),
            },
            component: FormFieldRichText
          }
        }
      ],
      items: [],
      loading: false,
      serverItemsLength: 0,
      globalLinks: {},
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: 'id',
        sortType: 'asc',
      },
      showForm: false,
    }
  },
  methods: {
    async loadFormServer() {
      this.loading = true;

      this.$store.dispatch('getEmailRules', {...this.serverOptions, filter: {}})
          .then((result) => {
            this.items = result.data;
            this.serverItemsLength = result.total;
            this.globalLinks = result._links;
            this.loading = false;
          });
    },
    async submitCreateForm(values) {
      let alert = this.$refs.alert;
      this.$store.dispatch('createEmailRule', values)
          .then(() => {
            this.loadFormServer();
            this.showForm = false;
            alert.success('Successfully created!', 2000);
          })
          .catch(() => {
            alert.error('Create failed!', 2000);
          });
    },
    createSubmitEditFormCallback(editedEntityId) {
      const loadFormServerProxy = this.loadFormServer;
      const replaceTagsWithPlaceholdersProxy = this.replaceTagsWithPlaceholders;
      const showFormProxy = (value) => this.showForm = value;
      let alert = this.$refs.alert;
      return async function submitEditForm(values) {
        values.emailTemplate = replaceTagsWithPlaceholdersProxy(values.emailTemplate);
        this.$store.dispatch('updateEmailRule', {data: values, id: editedEntityId})
            .then(() => {
              loadFormServerProxy();
              showFormProxy(false);
              alert.success('Successfully updated!', 2000);
            })
            .catch((reason) => {
              console.log(reason);
              alert.error('Update failed!', 2000);
            });
      }
    },
    handleOperation(url, operationCode, entry) {
      let formComponent = this.$refs['form'];
      let alert = this.$refs.alert;
      switch (operationCode) {
        case 'create':
          formComponent.setSubmitCallback(this.submitCreateForm);
          formComponent.empty();
          this.showForm = true;
          break;
        case 'update':
          formComponent.setSubmitCallback(this.createSubmitEditFormCallback(entry.id));
          formComponent.prefill(entry);
          this.showForm = true;
          break;
        case 'remove':
          this.$store.dispatch('deleteEmailRule', entry)
              .then(() => {
                this.loadFormServer();
                alert.success('Successfully deleted!', 2000);
              })
              .catch(() => {
                alert.error('Delete failed!', 2000);
              });
          break;
      }
    },
    formatExecutionTime(value) {
      let years = value.match(/(\d+)Y/)[1]
      let months = value.match(/(\d+)M/)[1]
      let days = value.match(/(\d+)DT/)[1]
      let hours = value.match(/(\d+)H/)[1]

      return years + 'Y ' + months + 'M ' + days + 'D ' + hours + 'H';
    },
    replaceTagsWithPlaceholders(emailTemplate) {
      let tempElement = document.createElement('div');
      tempElement.innerHTML = emailTemplate;
      let tagElements = tempElement.getElementsByClassName("mention");

      for (let i = 0; i < tagElements.length; i++) {
        tagElements[i].innerHTML = tagElements[i].getAttribute('data-placeholder-tag')
      }

      return tempElement.innerHTML;
    },
    formatBoolean(booleanValue){
      if (booleanValue === true) {
        return '✓';
      } else {
        return '✗';
      }
    }
  },
  mounted() {
    this.loadFormServer()
  },
  watch: {
    serverOptions: {
      handler() {
        this.loadFormServer();
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>