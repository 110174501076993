<template>
  <div class="position-relative">
    <AlertPopup ref="alert"></AlertPopup>
    <div class="container-fluid">
      <div class="row my-2">
        <div class="col-12">
          <h2>{{ $t('grid.revisions.header') }}</h2>
        </div>
      </div>
      <GridControlTable>
        <template v-slot:actions>
          <div v-for="(item, key) in globalLinks" v-bind:key="key">
            <button type="button" class="btn btn-custom-primary" @click="handleOperation(item, key, null)">
              <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="30" class="pointer">
            </button>
          </div>
        </template>
        <template v-slot:filters>
          <FilterTab :filter-data="filterData" @update-filter="loadFormServer"></FilterTab>
        </template>
        <template v-slot:body>
          <EasyDataTable
              theme-color="var(--accent)"
              buttons-pagination
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="items"
          >
            <template #loading>
              <img src="@/assets/loading.gif" style="width: 80px; height: 80px;" alt="loading"/>
            </template>
            <template #item-createdAt="items">
              {{ formatDateTime(items.createdAt) }}
            </template>
            <template #item-validTill="items">
              {{ formatDateTime(items.validTill) }}
            </template>
            <template #item-state="items">
              {{ $t('revision.state.' + items.state) }}
            </template>
            <template #item-revisionType="items">
              {{ $t('revision.type.' + items.revisionType) }}
            </template>
            <template #item-_links="items">
              <div class="d-flex justify-content-evenly">
                <a v-for="(item, key) in items._links" v-bind:key="key" @click="handleOperation(item, key, items)">
                  <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="15" class="pointer">
                </a>
              </div>
            </template>
          </EasyDataTable>
        </template>
      </GridControlTable>
    </div>
    <OffCanvas :show="showForm" @close-request="showForm = false">
      <UniversalForm :field-configuration="formFieldConfiguration" ref="form"></UniversalForm>
    </OffCanvas>
  </div>
</template>

<script>
import OffCanvas from "@/components/admin/common/grid/OffCanvas.vue";
import UniversalForm from "@/components/admin/common/edit-form/UniversalForm.vue";
import FormFieldCollection from "@/components/admin/common/edit-form/fields/FormFieldCollection.vue";
import AlertPopup from "@/components/admin/common/grid/AlertPopup.vue";
import FormFieldString from "@/components/admin/common/edit-form/fields/FormFieldString.vue";
import FormFieldText from "@/components/admin/common/edit-form/fields/FormFieldText.vue";
import FormFieldPrice from "@/components/admin/common/edit-form/fields/FormFieldPrice.vue";
import {decimal, minLength, minValue, required} from "@vuelidate/validators";
import FormFieldDatePicker from "@/components/admin/common/edit-form/fields/FormFieldDatePicker.vue";
import FormFieldSelect from "@/components/admin/common/edit-form/fields/FormFieldSelect.vue";
import FormFieldFileBox from "@/components/admin/common/edit-form/fields/FormFieldFileBox.vue";
import moment from "moment/moment";
import GridControlTable from "@/components/admin/common/grid/GridControlTable.vue";
import FilterTab from "@/components/admin/common/filtering/FilterTab.vue";

export default {
  name: 'RevisionsGrid',
  components: {
    FilterTab,
    GridControlTable,
    AlertPopup,
    OffCanvas,
    EasyDataTable: window['EasyDataTable'],
    UniversalForm: UniversalForm
  },
  data() {
    return {
      headers: [
        {text: "ID", value: "id", sortable: true},
        {text: this.$t('grid.revisions.grid.header.customer'), value: "customer.name"},
        {text: this.$t('grid.revisions.grid.header.created_at'), value: "createdAt", sortable: true},
        {text: this.$t('grid.revisions.grid.header.valid_till'), value: "validTill", sortable: true},
        {text: this.$t('grid.revisions.grid.header.state'), value: "state"},
        {text: this.$t('grid.revisions.grid.header.revision_type'), value: "revisionType"},
        {text: this.$t('grid.revisions.grid.header.tags'), value: "tags"},
        {text: this.$t('grid.revisions.grid.header.price'), value: "price"},
        {text: this.$t('grid.revisions.grid.header.created_by'), value: "createdBy.email"},
        {text: this.$t('grid.revisions.grid.header.operations'), value: "_links"},
      ],
      filterData: {
        createdAt: {
          title: this.$t('grid.revisions.grid.header.created_at'),
          value: '',
          operation: 'swith', // Hacked so there is no need for equal date on BE
          inputType: 'date'
        },
        createdByEmail: {
          title: this.$t('grid.revisions.grid.header.created_by'),
          value: '',
          operation: 'like',
          inputType: 'string'
        },
        state: {
          title: this.$t('grid.revisions.grid.header.state'),
          value: '',
          operation: 'eq',
          inputType: 'revisionState'
        },
        revisionType: {
          title: this.$t('grid.revisions.grid.header.revision_type'),
          value: '',
          operation: 'eq',
          inputType: 'revisionType'
        },
        validTill: {
          title: this.$t('grid.revisions.grid.header.valid_till'),
          value: '',
          operation: 'daterange',
          inputType: 'date-range'
        },
        tags: {
          title: this.$t('grid.revisions.grid.header.tags'),
          value: '',
          operation: 'like',
          inputType: 'string'
        },
        customerName: {
          title: this.$t('grid.revisions.grid.header.customer'),
          value: '',
          operation: 'swith',
          inputType: 'string'
        },
      },
      // files
      formFieldConfiguration: [
        {
          price: {
            key: 'price',
            fieldOptions: {
              title: this.$t('grid.revisions.form.price.title'),
              validations: {
                required,
                minValue: minValue(0),
                decimal
              },
            },
            component: FormFieldPrice
          },
          tags: {
            key: 'tags',
            fieldOptions: {
              title: this.$t('grid.revisions.form.tags.title'),
              component: FormFieldString,
              validations: {
                required,
              },
            },
            component: FormFieldCollection
          }
        },
        {
          state: {
            key: 'state',
            fieldOptions: {
              title: this.$t('grid.revisions.form.state.title'),
              validations: {
                required,
              },
              selectOptions: {
                SATISFACTORY: 'Satisfactory',
                UNSATISFACTORY: 'Unsatisfactory'
              }
            },
            component: FormFieldSelect
          },
          revisionType: {
            key: 'revisionType',
            fieldOptions: {
              title: this.$t('grid.revisions.form.revision_type.title'),
              validations: {
                required,
              },
              selectOptions: {
                DEFAULT: 'Default',
                REPEATED: 'Repeated',
                SPECIAL: 'Special',
              }
            },
            component: FormFieldSelect
          },
        },
        {
          validTill: {
            key: 'validTill',
            fieldOptions: {
              title: this.$t('grid.revisions.form.valid_till.title'),
              validations: {
                required,
              },
            },
            valueFormatter: (value) => {
              return value !== null ? value.split('T')[0] : null
            },
            component: FormFieldDatePicker
          },
          'emailRules.id': {
            key: 'emailRules.id',
            fieldOptions: {
              title: this.$t('grid.revisions.form.email_rules.title'),
              component: FormFieldSelect,
              validations: {
                required,
              },
              allowRepeat: false,
              selectOptions: this.loadEmailRuleOptions()
            },
            component: FormFieldCollection
          }
        },
        {
          address: {
            key: 'address',
            fieldOptions: {
              title: this.$t('grid.revisions.form.address.title'),
              validations: {
                required,
                minLength: minLength(3)
              },
            },
            component: FormFieldString
          },
          'customer.id': {
            key: 'customer.id',
            fieldOptions: {
              title: this.$t('grid.revisions.form.customer.title'),
              validations: {
                required,
              },
              selectOptions: this.loadCustomersOptions()
            },
            component: FormFieldSelect
          },
        },
        {
          description: {
            key: 'description',
            fieldOptions: {
              title: this.$t('grid.revisions.form.description.title'),
            },
            component: FormFieldText
          },
        },
        {
          files: {
            key: 'files',
            fieldOptions: {
              title: this.$t('grid.revisions.form.files.title'),
            },
            component: FormFieldFileBox
          },
        },
      ],
      items: [],
      loading: false,
      serverItemsLength: 0,
      globalLinks: {},
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: 'id',
        sortType: 'asc',
      },
      showForm: false,
    }
  },
  methods: {
    async loadEmailRuleOptions() {
      let rulesOptions = {};
      let emailRules = await this.$store.dispatch('getEmailRules', {
        page: 1,
        rowsPerPage: 100,
        sortBy: 'id',
        filter: {}
      });
      emailRules.data.forEach((value) => {
        rulesOptions[value.id] = value.id + ': ' + value.subject;
      });
      return rulesOptions;
    },
    async loadCustomersOptions() {
      let customerOptions = {};
      let customers = await this.$store.dispatch('getCustomers', {
        page: 1,
        rowsPerPage: 100,
        sortBy: 'id',
        filter: {}
      });
      customers.data.forEach((value) => {
        customerOptions[value.id] = value.id + ': ' + value.name;
      });
      return customerOptions;
    },
    async loadFormServer() {
      this.loading = true;

      this.$store.dispatch('getRevisions', {...this.serverOptions, filter: this.filterData})
          .then((result) => {
            this.items = result.data;
            this.serverItemsLength = result.total;
            this.globalLinks = result._links;
            this.loading = false;
          });
    },
    createSubmitCreateFormCallback() {
      let selfReference = this;
      return async function submitCreateForm(values) {
        let files = this.$refs[`field_files`][0];
        await files.upload();

        values['customer'] = {id: Number(values['customer.id'])}
        delete values['customer.id'];

        values['emailRules'] = values['emailRules.id'].map((value) => {
          return {id: Number(value)}
        });
        delete values['emailRules.id'];

        this.$store.dispatch('createRevision', values)
            .then(() => {
              selfReference.loadFormServer();
              selfReference.showForm = false;
              alert.success('Successfully created!', 2000);
            })
            .catch(() => {
              alert.error('Create failed!', 2000);
            });
      }
    },
    createSubmitEditFormCallback(editedEntityId) {
      const loadFormServerProxy = this.loadFormServer;
      const showFormProxy = (value) => this.showForm = value;
      let alert = this.$refs.alert;
      return async function submitEditForm(values) {
        let files = this.$refs[`field_files`][0];
        await files.upload();

        values['customer'] = {id: Number(values['customer.id'])}
        delete values['customer.id'];

        values['emailRules'] = values['emailRules.id'].map((value) => {
          return {id: Number(value)}
        });
        delete values['emailRules.id'];

        this.$store.dispatch('updateRevision', {data: values, id: editedEntityId})
            .then(() => {
              loadFormServerProxy();
              showFormProxy(false);
              alert.success('Successfully updated!', 2000);
            })
            .catch((reason) => {
              console.log(reason);
              alert.error('Update failed!', 2000);
            });
      }
    },
    handleOperation(url, operationCode, entry) {
      let formComponent = this.$refs['form'];
      let alert = this.$refs.alert;
      switch (operationCode) {
        case 'create':
          formComponent.setSubmitCallback(this.createSubmitCreateFormCallback());
          formComponent.empty();
          this.showForm = true;
          break;
        case 'update':
          formComponent.setSubmitCallback(this.createSubmitEditFormCallback(entry.id));
          formComponent.prefill(entry);
          this.showForm = true;
          break;
        case 'remove':
          this.$store.dispatch('deleteRevision', entry)
              .then(() => {
                this.loadFormServer();
                alert.success('Successfully deleted!', 2000);
              })
              .catch(() => {
                alert.error('Delete failed!', 2000);
              });
          break;
      }
    },
    formatDateTime(timeString) {
      return timeString !== null ? moment(String(timeString)).format('D.M.YYYY') : '';
    },
  },
  mounted() {
    this.loadFormServer()
  },
  watch: {
    serverOptions: {
      handler() {
        this.loadFormServer();
      },
      deep: true
    },
  }
}
</script>

<style>

</style>