import {createStore} from 'vuex'
import axios from "axios";
import Filter from './helpers/Filter'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    reducer: (state) => ({account: state.account}),
})

const store = createStore({
    plugins: [vuexLocal.plugin],
    state: {
        apiAddress: "https://api.smartvolt.sk/api/v1/",
        // apiAddress: "/api/api/v1/",
        account: null,
        customers: null,
        users: null,
        emailRulesExecuted: null,
        emailRules: null,
        revisions: null,
        availableNavigationLinks: [
            {
                label: "navigation.label.customers",
                path: "/customers"
            },
            {
                label: "navigation.label.revisions",
                path: "/revisions"
            },
            {
                label: "navigation.label.email_rules",
                path: "/email-rules"
            },
            {
                label: "navigation.label.email_rules_executed",
                path: "/email-rules-executed"
            },
            {
                label: "navigation.label.users",
                path: "/users"
            },
        ],
    },
    getters: {
        customers: (state) => state.customers,
        account: (state) => state.account,
        users: (state) => state.users,
        apiAddress: (state) => state.apiAddress,
        emailRulesExecuted: (state) => state.emailRulesExecuted,
        emailRules: (state) => state.emailRules,
        revisions: (state) => state.revisions,
        auth: (state) => {
            return {
                headers: {
                    'Authorization': 'Bearer ' + state.account.token,
                    'Content-Type': 'application/json', //TODO UPGRADES Vymysliet header generation inak
                },
            };
        },
        hasValidToken: (state) => {
            if (state.account === null) {
                return false;
            }
            if (state.account.token === undefined || state.account.expiresAt === undefined) {
                return false;
            }
            return Date.parse(state.account.expiresAt) > new Date();
        }
    },
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        setCustomers(state, customers) {
            state.customers = customers;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setEmailRulesExecuted(state, emailRulesExecuted) {
            state.emailRulesExecuted = emailRulesExecuted;
        },
        setEmailRules(state, emailRules) {
            state.emailRules = emailRules;
        },
        setRevisions(state, revisions) {
            state.revisions = revisions;
        }
    },
    actions: {
        async login({commit}, {username, password}) {
            let url = this.getters.apiAddress + "authorization/login"
            let data = await axios.post(url, {
                username,
                password
            });

            commit('setAccount', data.data)
        },
        async refreshToken({commit}) {
            if (!this.getters.hasValidToken) {
                return;
            }

            let url = this.getters.apiAddress + "authorization/refresh"
            let data = await axios.post(url, {
                    token: this.getters.account.token
                },
                this.getters.auth);

            commit('setAccount', data.data)
        },
        async logout({commit}) {
            commit('setAccount', null)
        },
        // eslint-disable-next-line
        async getCustomers({commit}, {page, rowsPerPage, sortBy, sortType, filter}) {
            // TODO caching

            let url = this.getters.apiAddress + "customers" + (new Filter(page, rowsPerPage, sortBy, sortType, filter)).buildFilterQuery();

            let retrievedData;
            try {
                const response = await axios.get(
                    url,
                    this.getters.auth
                );
                retrievedData = response.data;
            } catch (error) {
                console.log(error);
            }

            commit('setCustomers', retrievedData)

            return this.getters.customers
        },
        // eslint-disable-next-line
        async createCustomer({commit}, data) {
            let url = this.getters.apiAddress + "customers";

            await axios.post(
                url,
                data,
                this.getters.auth
            )
                .then(() => {
                    // TODO add new customer
                });
        },
        // eslint-disable-next-line
        async updateCustomer({commit}, {data, id}) {
            let url = this.getters.apiAddress + "customers/" + id;

            // eslint-disable-next-line
            const result = await axios.put(
                url,
                data,
                this.getters.auth
            )
        },
        // eslint-disable-next-line
        async deleteCustomer({commit}, entry) {
            let url = this.getters.apiAddress + "customers/" + entry.id;

            await axios.delete(
                url,
                this.getters.auth
            )
                .then(() => {
                    // TODO remove from custommers
                });
        },
        // eslint-disable-next-line
        async getUsers({commit}, {page, rowsPerPage, sortBy, sortType, filter}) {
            let url = this.getters.apiAddress + "users" + (new Filter(page, rowsPerPage, sortBy, sortType, filter)).buildFilterQuery();

            let retrievedData;
            try {
                const response = await axios.get(
                    url,
                    this.getters.auth
                );
                retrievedData = response.data;
            } catch (error) {
                console.log(error);
            }

            commit('setUsers', retrievedData)

            return this.getters.users
        },
        // eslint-disable-next-line
        async createUser({commit}, data) {
            let url = this.getters.apiAddress + "users";

            await axios.post(
                url,
                data,
                this.getters.auth
            )
                .then(() => {
                    // TODO add new customer
                });
        },
        // eslint-disable-next-line
        async updateUser({commit}, {data, id}) {
            let url = this.getters.apiAddress + "users/" + id;

            // eslint-disable-next-line
            const result = await axios.put(
                url,
                data,
                this.getters.auth
            )
        },
        // eslint-disable-next-line
        async deleteUser({commit}, entry) {
            let url = this.getters.apiAddress + "users/" + entry.id;

            await axios.delete(
                url,
                this.getters.auth
            )
                .then(() => {
                    // TODO remove from custommers
                });
        },
        // eslint-disable-next-line
        async getEmailRulesExecuted({commit}, {page, rowsPerPage, sortBy, sortType, filter}) {
            let url = this.getters.apiAddress + "email-rules-executed" + (new Filter(page, rowsPerPage, sortBy, sortType, filter)).buildFilterQuery();

            let retrievedData;
            try {
                const response = await axios.get(
                    url,
                    this.getters.auth
                );
                retrievedData = response.data;
            } catch (error) {
                console.log(error);
            }

            commit('setEmailRulesExecuted', retrievedData);

            return this.getters.emailRulesExecuted;
        },
        // eslint-disable-next-line
        async getEmailRules({commit}, {page, rowsPerPage, sortBy, sortType, filter}) {
            let url = this.getters.apiAddress + "email-rules" + (new Filter(page, rowsPerPage, sortBy, sortType, filter)).buildFilterQuery();

            let retrievedData;
            try {
                const response = await axios.get(
                    url,
                    this.getters.auth
                );
                retrievedData = response.data;
            } catch (error) {
                console.log(error);
            }

            commit('setEmailRules', retrievedData);

            return this.getters.emailRules;
        },
        // eslint-disable-next-line
        async createEmailRule({commit}, data) {
            let url = this.getters.apiAddress + "email-rules";

            await axios.post(
                url,
                data,
                this.getters.auth
            )
                .then(() => {
                    // TODO add new customer
                });
        },
        // eslint-disable-next-line
        async updateEmailRule({commit}, {data, id}) {
            let url = this.getters.apiAddress + "email-rules/" + id;

            // eslint-disable-next-line
            const result = await axios.put(
                url,
                data,
                this.getters.auth
            )
        },
        // eslint-disable-next-line
        async deleteEmailRule({commit}, entry) {
            let url = this.getters.apiAddress + "email-rules/" + entry.id;

            await axios.delete(
                url,
                this.getters.auth
            )
                .then(() => {
                    // TODO remove from custommers
                });
        },
        // eslint-disable-next-line
        async getRevisions({commit}, {page, rowsPerPage, sortBy, sortType, filter}) {
            // TODO caching

            let url = this.getters.apiAddress + "revisions" + (new Filter(page, rowsPerPage, sortBy, sortType, filter)).buildFilterQuery();

            let retrievedData;
            try {
                const response = await axios.get(
                    url,
                    this.getters.auth
                );
                retrievedData = response.data;
            } catch (error) {
                console.log(error);
            }

            commit('setRevisions', retrievedData)

            return this.getters.revisions;
        },
        // eslint-disable-next-line
        async createRevision({commit}, data) {
            let url = this.getters.apiAddress + "revisions";

            await axios.post(
                url,
                data,
                this.getters.auth
            )
                .then(() => {
                    // TODO add new customer
                });
        },
        // eslint-disable-next-line
        async updateRevision({commit}, {data, id}) {
            let url = this.getters.apiAddress + "revisions/" + id;

            // eslint-disable-next-line
            const result = await axios.put(
                url,
                data,
                this.getters.auth
            )
        },
        // eslint-disable-next-line
        async deleteRevision({commit}, entry) {
            let url = this.getters.apiAddress + "revisions/" + entry.id;

            await axios.delete(
                url,
                this.getters.auth
            )
                .then(() => {
                    // TODO remove from custommers
                });
        },
        // eslint-disable-next-line
        async uploadFiles({commit}, formData) {
            let url = this.getters.apiAddress + "files";
            let auth = this.getters.auth;
            auth.headers["Content-Type"] = "multipart/form-data";
            let retrievedData;
            await axios.post(url, formData, auth)
                .then((response) => {
                    retrievedData = response;
                });

            return retrievedData;
        },
        // eslint-disable-next-line
        async deleteFile({commit}, entry) {
            let url = this.getters.apiAddress + "files/" + entry.id;

            await axios.delete(
                url,
                this.getters.auth
            )
                .then(() => {
                    // TODO remove from custommers
                });
        },
        // eslint-disable-next-line
        async downloadFile({commit}, {fileId, fileType, fileName}) {
            let url = this.getters.apiAddress + "files/" + fileId + "/download";
            axios
                .get(
                    url,
                    this.getters.auth
                )
                .then(response => {
                    let fileURL = window.URL.createObjectURL(new Blob([response.data], {type: fileType}));
                    let fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                })
        }
    },
});

export default store;