import {createApp} from 'vue'
import store from './store.js'
import router from "./router.js";
import App from "./App.vue";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import messages from './translations'
import { createI18n } from 'vue-i18n'

import 'vue3-easy-data-table/dist/style.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./styles/global.css"

const i18n = createI18n({
    locale: 'sk',
    fallbackLocale: 'sk',
    messages,
})

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.component('EasyDataTable', Vue3EasyDataTable);

app.mount('#app');
