<template>
  <div class="position-relative">
    <div class="container-fluid">
      <div class="row my-2">
        <div class="col-12">
          <h2>{{ $t('grid.email_rules_executed.header') }}</h2>
        </div>
      </div>
      <GridControlTable>
        <template v-slot:actions>
          <div v-for="(item, key) in globalLinks" v-bind:key="key">
            <button type="button" class="btn btn-custom-primary" @click="handleOperation(item, key, null)">
              <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="30" class="pointer">
            </button>
          </div>
        </template>
        <template v-slot:filters>
          <FilterTab :filter-data="filterData" @update-filter="loadFormServer"></FilterTab>
        </template>
        <template v-slot:body>
          <EasyDataTable
              theme-color="var(--accent)"
              buttons-pagination
              v-model:server-options="serverOptions"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :headers="headers"
              :items="items"
          >
            <template #loading>
              <img src="@/assets/loading.gif" style="width: 80px; height: 80px;" alt="loading"/>
            </template>
            <template #item-executedAt="items">
              {{ formatDateTime(items.executedAt) }}
            </template>
            <template #item-_links="items">
              <div class="d-flex justify-content-evenly">
                <a v-for="(item, key) in items._links" v-bind:key="key" @click="handleOperation(item, key, items)">
                  <img :src="require('@/assets/action-icons/' + key + '.png')" :alt="key" width="15" class="pointer">
                </a>
              </div>
            </template>
          </EasyDataTable>
        </template>
      </GridControlTable>
    </div>
    <OffCanvas :show="showDetail" @close-request="showDetail = false" title="EMAIL OVERVIEW">
      <div>
        <div class="bg-light w-100 mb-2">
          <div>
            Sent at: {{ formatDateTime(detailEntry.executedAt) }}
          </div>
          <div>
            To: <span v-for="(item, key) in detailEntry.recipients" v-bind:key="key">{{ item }}, </span>
          </div>
        </div>
        <RenderedHTMLOverviewBox :content="detailEntry.content"></RenderedHTMLOverviewBox>
      </div>

    </OffCanvas>
  </div>
</template>

<script>

import OffCanvas from "@/components/admin/common/grid/OffCanvas.vue";
import RenderedHTMLOverviewBox from "@/components/admin/common/RenderedHTMLOverviewBox.vue";
import moment from "moment";
import GridControlTable from "@/components/admin/common/grid/GridControlTable.vue";
import FilterTab from "@/components/admin/common/filtering/FilterTab.vue";

export default {
  name: 'EmailRulesExecutedGrid',
  components: {
    FilterTab,
    GridControlTable,
    RenderedHTMLOverviewBox,
    OffCanvas,
    EasyDataTable: window['EasyDataTable'],
  },
  data() {
    return {
      headers: [
        {text: "ID", value: "id", sortable: true},
        {text: this.$t('grid.email_rules_executed.grid.header.executed_at'), value: "executedAt", sortable: true},
        {text: this.$t('grid.email_rules_executed.grid.header.recipients'), value: "recipients"},
        {text: this.$t('grid.email_rules_executed.grid.header.operations'), value: "_links", width: 150},
      ],
      filterData: {
        recipients: {
          title: this.$t('grid.email_rules_executed.grid.header.recipients'),
          value: '',
          operation: 'like',
          inputType: 'string'
        },
        executedAt: {
          title: this.$t('grid.email_rules_executed.grid.header.executed_at'),
          value: '',
          operation: 'swith',
          inputType: 'date'
        }
      },
      items: [],
      loading: false,
      serverItemsLength: 0,
      globalLinks: {},
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
        sortBy: 'id',
        sortType: 'asc',
      },
      showDetail: false,
      detailEntry: {},
    }
  },
  methods: {
    async loadFormServer() {
      this.loading = true;

      this.$store.dispatch('getEmailRulesExecuted', {...this.serverOptions, filter: this.filterData})
          .then((result) => {
            let data = result.data;
            data.forEach((element, index) => {
              data[index]._links = {detail: ''};
            });

            this.items = data;
            this.serverItemsLength = result.total;
            this.globalLinks = result._links;
            this.loading = false;
          });
    },
    // eslint-disable-next-line
    handleOperation(url, operationCode, entry) {
      switch (operationCode) {
        case 'detail':
          this.detailEntry = entry;
          this.showDetail = true;
          break;
      }
    },
    formatDateTime(timeString) {
      return moment(String(timeString)).format('D.M.YYYY H:m');
    }
  },
  mounted() {
    this.loadFormServer()
  },
  watch: {
    serverOptions: {
      handler() {
        this.loadFormServer();
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>