export default {
    sk: {
        login: {
            form: {
                email: "Email",
                password: "Heslo",
                submit: "Odoslať"
            }
        },
        navigation: {
            label: {
                users: "Používatelia",
                customers: "Zákazníci",
                revisions: "Revízie",
                email_rules: "Automatizované správy",
                email_rules_executed: "Odoslané správy",
                logout: "Odhlásiť"
            }
        },
        form: {
            submit: "Odoslať",
            field: {
                file_box: {
                    upload: "Nahrať"
                }
            }
        },
        grid: {
            customers: {
                header: "Zákazníci",
                grid: {
                    header: {
                        name: "Názov",
                        address: "Adresa",
                        identification: "Identifikátor",
                        primary_contact_email: "Hlavný email",
                        emails_for_cc: "Ostatné emaily",
                        tags: "Tagy",
                        operations: "Operácie"
                    }
                },
                form: {
                    name: {
                        title: "Názov"
                    },
                    identification: {
                        title: "Identifikátor"
                    },
                    primary_contact_email: {
                        title: "Hlavný email"
                    },
                    emails_for_cc: {
                        title: "Ostatné emaily"
                    },
                    address: {
                        title: "Adresa"
                    },
                    tags: {
                        title: "Tagy"
                    },
                    description: {
                        title: "Popis"
                    }
                }
            },
            email_rules: {
                header: "Automatizované správy",
                grid: {
                    header: {
                        execution_time: "Doba odoslania (od konca platnosti)",
                        subject: "Subjekt",
                        recipients: "Príjemcovia",
                        send_to_customer_primary: "Zaslať zákazníkov primárny email",
                        send_to_cc: "Zaslať na zákazníkove sekundárne emaily",
                        operations: "Operácie"
                    }
                },
                form: {
                    subject: {
                        title: "Subjekt"
                    },
                    recipients: {
                        title: "Príjemcovia"
                    },
                    execution_time: {
                        title: "Čas vykonania (pred skončením platnosti)"
                    },
                    send_to_customer_primary: {
                        title: "Zaslať zákazníkov primárny email"
                    },
                    send_to_cc: {
                        title: "Zaslať na zákazníkove sekundárne emaily"
                    },
                    email_template: {
                        title: "Šablóna"
                    }
                },
            },
            email_rules_executed: {
                header: "Odoslané správy",
                grid: {
                    header: {
                        executed_at: "Dátum odoslania",
                        recipients: "Príjemcovia",
                        operations: "Operácie"
                    }
                }
            },
            revisions: {
                header: "Revízie",
                grid: {
                    header: {
                        created_at: "Vytvorené",
                        created_by: "Vytvoril",
                        state: "Stav",
                        revision_type: "Typ",
                        valid_till: "Platné do",
                        tags: "Tagy",
                        price: "Cena",
                        customer: "Zákazník",
                        operations: "Operácie"
                    }
                },
                form: {
                    price: {
                        title: "Cena"
                    },
                    tags: {
                        title: "Tagy"
                    },
                    state: {
                        title: "Stav"
                    },
                    revision_type: {
                        title: "Typ"
                    },
                    valid_till: {
                        title: "Platné do"
                    },
                    email_rules: {
                        title: "Pravidlá pre automatizované správy"
                    },
                    address: {
                        title: "Adresa"
                    },
                    customer: {
                        title: "Zákazník"
                    },
                    description: {
                        title: "Popis"
                    },
                    files: {
                        title: "Súbory"
                    }
                }
            },
            users: {
                header: "Používatelia",
                grid: {
                    header: {
                        email: "Email",
                        roles: "Role",
                        operations: "Operácie"
                    }
                },
                form: {
                    email: {
                        title: "Email"
                    },
                    roles: {
                        title: "Role"
                    },
                    password: {
                        title: "Heslo"
                    }
                }
            },
            common: {
                filter_button: 'Filtruj'
            }
        },
        revision: {
            type: {
                DEFAULT: 'Základná',
                REPEATED: 'Opakovaná',
                SPECIAL: 'Špeciálna',
            },
            state: {
                SATISFACTORY: 'Vyhovujúci',
                UNSATISFACTORY: 'Nevyhovujúci'
            },
        }
    }
}