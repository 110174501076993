<template>
  <div class="w-100 rounded border border-light bg-light">
    <iframe :srcdoc="content"></iframe>
  </div>
</template>

<script>
export default {
  name: "RenderedHTMLOverviewBox",
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>