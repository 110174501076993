<template>
  <div>
    <div v-for="(item, index) in filterData" v-bind:key="index" class="w-50 row mx-3">
      <label class="p-0 filter-label" :for="index">{{ item.title }}</label>
      <input class="form-control" v-model="item.value" :name="index" v-if="item.inputType === 'string'" @keyup.enter="updateFilter"/>
      <VueDatePicker v-model="item.value" v-if="item.inputType === 'date'" :model-type="'yyyy-MM-dd'"
                     :enable-time-picker="false" :auto-apply="true"></VueDatePicker>
      <VueDatePicker v-model="item.value" v-if="item.inputType === 'date-range'" :model-type="'yyyy-MM-dd'"
                     :enable-time-picker="false" :auto-apply="true" range></VueDatePicker>
      <RevisionTypeSelector v-model:value="item.value" v-if="item.inputType === 'revisionType'"></RevisionTypeSelector>
      <RevisionStateSelector v-model:value="item.value" v-if="item.inputType === 'revisionState'"></RevisionStateSelector>
    </div>
    <div class="d-flex">
      <button type="button" class="mx-3 my-2 btn btn-custom-accent" @click="updateFilter">
        {{ $t('grid.common.filter_button') }}
      </button>
      <img src="@/assets/action-icons/x.png" alt="resetFilter" v-if="filterActive" @click="resetFilter" class="pointer my-auto" style="height: 20px">
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import RevisionTypeSelector from "@/components/admin/common/filtering/RevisionTypeSelector.vue";
import RevisionStateSelector from "@/components/admin/common/filtering/RevisionStateSelector.vue";

export default {
  name: "FilterTab",
  components: {RevisionStateSelector, RevisionTypeSelector, VueDatePicker},
  props: ['filterData'],
  data() {
    return {
      filterActive: false,
    }
  },
  methods: {
    updateFilter() {
      this.$emit('updateFilter');
      this.filterActive = true;
    },
    resetFilter() {
      let filterDataCopy = Object.assign({}, this.filterData);
      for (const [key] of Object.entries(filterDataCopy)) {
        filterDataCopy[key].value = '';
      }
      this.$emit('update:filterData', filterDataCopy);

      this.filterActive = false;
      this.$emit('updateFilter');
    }
  }
}
</script>

<style scoped>
.filter-label {
  font-size: 0.8rem;
}

.form-control {
  padding: 0 0.6rem;
}

.form-select {
  padding: 0 0.6rem;
}

.btn {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
</style>